//@flow
import React, { Component } from 'react';
// $FlowFixMe.
import TextField from '@material-ui/core/TextField';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  signup,
  getAllCohorts,
  getAllSchools,
  getAllCompanies,
  getAllRoles,
} from '../../redux/actions';
// import DisplayFormikState from '../DisplayFormikState';
import MultiSelect from '../Select/MultiSelect';
// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import zIndex from '@material-ui/core/styles/zIndex';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

type Props = {
  error: string,
  submitting: boolean,
  allCohorts: Array<Object>,
  allSchools: Array<Object>,
  allRoles: Array<Object>,
  signup: Function,
  values: Object,
  errors: Object,
  handleChange: Function,
  resetForm: Function,
  allCompanies: Array<Object>,
  getAllCohorts: Function,
  getAllSchools: Function,
  classes: Object,
};

class AddUserForm extends Component<Props> {
  componentDidMount() {
    const { getAllCohorts, getAllSchools } = this.props;
    getAllCohorts();
    getAllSchools();
  }
  render() {
    const refereeId = window.location.href.split('?')[1];
    const passReferralBonusToIncomingStudent = window.location.href.split('?')[2];
    const linkedInUrl = window.location.href.split('?')[3];
    const {
      values,
      handleChange,
      submitting,
      error,
      errors,
      classes,
      allCohorts,
      allSchools,
      allCompanies,
      allRoles,
      signup,
      resetForm,
    } = this.props;
    return (
      <form
        style={{ overflow: 'auto' }}
        className={classes.container}
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          signup(values, { refereeId, passReferralBonusToIncomingStudent, linkedInUrl });
          resetForm();
        }}
      >
        <h4>Add a User</h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Adding User</p>}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.error },
          }}
          message={<p>{error.message || error}</p>}
        />
        <TextField
          required
          variant="outlined"
          value={values.username}
          onChange={handleChange}
          id="username"
          label="Username"
          placeholder="Jane"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.username}
        />
        <TextField
          required
          variant="outlined"
          value={values.firstName}
          onChange={handleChange}
          id="firstName"
          label="First Name"
          placeholder="Jane"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.firstName}
        />
        <TextField
          required
          variant="outlined"
          value={values.lastName}
          onChange={handleChange}
          id="lastName"
          label="Last Name"
          placeholder="Doe"
          margin="normal"
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          helperText={errors.lastName}
        />
        <TextField
          required
          variant="outlined"
          value={values.address}
          onChange={handleChange}
          id="address"
          label="Address"
          placeholder="101, S. Carolina Street, Model Town"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.address}
        />
        <TextField
          required
          variant="outlined"
          value={values.city}
          onChange={handleChange}
          id="city"
          label="City"
          placeholder="Newyork"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.city}
        />
        <TextField
          required
          variant="outlined"
          value={values.state}
          onChange={handleChange}
          id="state"
          label="State"
          placeholder="Karnataka"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.state}
        />
        <TextField
          required
          variant="outlined"
          value={values.country}
          onChange={handleChange}
          id="country"
          label="Country"
          placeholder="U.S.A"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.country}
        />
        <TextField
          required
          variant="outlined"
          value={values.pincode}
          onChange={handleChange}
          id="pincode"
          label="Pincode"
          placeholder="974501"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.pincode}
        />
        <TextField
          required
          variant="outlined"
          value={values.phoneNumber}
          onChange={handleChange}
          id="phoneNumber"
          label="Phone Number"
          placeholder="9876944954"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.phoneNumber}
        />
        <TextField
          required
          variant="outlined"
          value={values.slackId}
          onChange={handleChange}
          id="slackId"
          label="slackId"
          margin="normal"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          value={values.email}
          onChange={handleChange}
          name="email"
          placeholder="janedoe@gmail.com"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.email}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={values.password}
          onChange={handleChange}
          name="password"
          placeholder=""
          autoComplete="password"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.password}
        />
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          type="Password"
          value={values.confirmPassword}
          onChange={handleChange}
          name="confirmPassword"
          placeholder=""
          autoComplete="confirmPassword"
          margin="normal"
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.myHelperTextStyle,
            },
          }}
          helperText={errors.password}
        />
        <TextField
          id="cohortId"
          variant="outlined"
          select
          label="Cohort"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.cohortId}
          onChange={handleChange('cohortId')}
          margin="normal"
        >
          {allCohorts.map(cohort => (
            <MenuItem key={cohort._id} value={cohort._id} style={{ fontSize: 15 }}>
              {cohort.name}
            </MenuItem>
          ))}
        </TextField>
        {/* <TextField
          required
          id="paidTillNow"
          variant="outlined"
          select
          label="Paid Till Now"
          style={styles.textField}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.paidTillNow}
          onChange={handleChange('paidTillNow')}
          margin="normal"
        >
          <MenuItem key={1} value={50} style={{ fontSize: 13 }}>
            $50
          </MenuItem>
          <MenuItem key={2} value={25} style={{ fontSize: 13 }}>
            $25
          </MenuItem>
          <MenuItem key={3} value={0} style={{ fontSize: 13 }}>
            $0
          </MenuItem>
        </TextField> */}
        <TextField
          required
          id="totalPayment"
          variant="outlined"
          select
          label="Total Payment"
          style={styles.textField}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.totalPayment}
          onChange={handleChange('totalPayment')}
          margin="normal"
        >
          <MenuItem key={1} value={799} style={{ fontSize: 13 }}>
            $799
          </MenuItem>
          <MenuItem key={2} value={699} style={{ fontSize: 13 }}>
            $699
          </MenuItem>
          <MenuItem key={6} value={599} style={{ fontSize: 13 }}>
            $599
          </MenuItem>
          <MenuItem key={3} value={449} style={{ fontSize: 13 }}>
            $449
          </MenuItem>
          <MenuItem key={5} value={399} style={{ fontSize: 13 }}>
            $399
          </MenuItem>
          <MenuItem key={7} value={299} style={{ fontSize: 13 }}>
            $299
          </MenuItem>
          <MenuItem key={8} value={249} style={{ fontSize: 13 }}>
            $249
          </MenuItem>

          <MenuItem key={4} value={199} style={{ fontSize: 13 }}>
            $199
          </MenuItem>
        </TextField>
        <TextField
          required
          id="noOfPendingInstallments"
          variant="outlined"
          select
          label="Installments"
          style={styles.textField}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.noOfPendingInstallments}
          onChange={handleChange('noOfPendingInstallments')}
          margin="normal"
        >
          <MenuItem key={1} value={1} style={{ fontSize: 13 }}>
            Single
          </MenuItem>
          <MenuItem key={2} value={2} style={{ fontSize: 13 }}>
            Two
          </MenuItem>
          <MenuItem key={3} value={3} style={{ fontSize: 13 }}>
            Three
          </MenuItem>
        </TextField>
        <TextField
          required
          id="billingLocation"
          variant="outlined"
          select
          label="Billing Location"
          style={styles.textField}
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.billingLocation}
          onChange={handleChange('billingLocation')}
          margin="normal"
        >
          <MenuItem key={1} value={1} style={{ fontSize: 13 }}>
            US
          </MenuItem>
          <MenuItem key={3} value={2} style={{ fontSize: 13 }}>
            India
          </MenuItem>
        </TextField>
        <TextField
          id="schoolId"
          variant="outlined"
          select
          label="School"
          InputProps={{
            classes: {
              input: classes.resize,
            },
          }}
          value={values.schoolId}
          onChange={handleChange('schoolId')}
          margin="normal"
        >
          {allSchools.map(school => (
            <MenuItem key={school._id} value={school._id} style={{ fontSize: 15 }}>
              {school.name}
            </MenuItem>
          ))}
        </TextField>
        <p style={styles.myHelperTextStyle}>{errors.roleIds}</p>
        <button type="submit">Submit</button>
        {/* <DisplayFormikState {...values} /> */}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  allCohorts: state.cohortState.allCohorts,
  allSchools: state.schoolState.allSchools,
  error: state.authState.error,
  submitting: state.authState.submittingSignUpRequest,
});

const mapDispatchToProps = dispatch => ({
  signup: (values, { refereeId, passReferralBonusToIncomingStudent, linkedInUrl }) =>
    dispatch(signup(values, { refereeId, passReferralBonusToIncomingStudent, linkedInUrl })),
  getAllCohorts: () => dispatch(getAllCohorts()),
  getAllSchools: () => dispatch(getAllSchools()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUserForm));
