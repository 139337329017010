//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAlumniList, handleAlumniListPersonPageClick } from '../../redux/actions';
import Custompaginate from '../Custompaginate/Custompaginate';
import Sidebar from '../Sidebar/Sidebar';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import './AlumniList.scss';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';

// $FlowFixMe.
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from '@material-ui/core';
import alumniListReducer from '../../redux/reducers/alumniList';
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
};
type Props = {
  getAlumniList: Function,
  alumniList: Array<Object>,
  handleAlumniListPersonPageClick: Function,
  activePageList: number,
  perPage: number,
  error: string,
  classes: Object,
  submitting: boolean,
};
class AlumniList extends Component<Props> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }
  componentDidMount() {
    const { getAlumniList } = this.props;
    const { id } = this.props.match.params;
    getAlumniList(id);
  }
  handleAlumniListPersonPageClick = data => {
    const { handleAlumniListPersonPageClick } = this.props;
    handleAlumniListPersonPageClick(data.selected);
  };

  render() {
    const { alumniList, activePageList, perPage, submitting, error, classes } = this.props;
    const { roles } = this.state;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="alumni">
          <div className="table">
            <h3 className="title">Alumni List</h3>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={submitting}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.submit },
              }}
              message={<p>Getting Alumni List...</p>}
            />

            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={error}
              autoHideDuration={6000}
              ContentProps={{
                classes: { root: classes.error },
              }}
              message={<p>{error}</p>}
            />
            <div className="thead">
              <div className="columnSmall">S. No</div>
              <div className="columnMedium">Name</div>
              <div className="columnMedium">Title</div>
              <div className="columnMedium">Email</div>
              {roles.includes('CohortManager') && <div className="columnMedium">Phone Number</div>}
              {roles.includes('CohortManager') && <div className="columnSmall">Wants CC</div>}
              <div className="columnMedium">Link</div>
            </div>
            {alumniList.map((alumni, index) => {
              var { Name, LinkedInProfile, Title, Email, PhoneNumber, WantsCC } = alumni;
              if (!LinkedInProfile.match(/^https?:\/\//i)) {
                LinkedInProfile = 'http://' + LinkedInProfile;
              }
              const bottomIdx = activePageList * perPage;
              const topIdx = bottomIdx + perPage;
              if (index >= bottomIdx && index < topIdx) {
                return (
                  <div className="tbody">
                    <div className="row">
                      <div className="columnSmall">{index + 1}</div>
                      <div className="columnMedium">{Name}</div>
                      <div className="columnMedium">{Title}</div>
                      <div className="columnMedium">{Email}</div>
                      {roles.includes('CohortManager') && (
                        <div className="columnMedium">{PhoneNumber}</div>
                      )}
                      {roles.includes('CohortManager') && (
                        <div className="columnSmall">{WantsCC}</div>
                      )}
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(`${LinkedInProfile}`, '_blank')}
                      >
                        <div className="columnMedium">LinkedInProfile</div>
                      </a>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <Custompaginate
            pageCount={alumniList.length / perPage}
            onPageChange={this.handleAlumniListPersonPageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activePageList: state.alumniListState.activePageList,
  perPage: state.alumniListState.perPage,
  alumniList: state.alumniListState.allAlumniList,
  error: state.alumniListState.error,
  submitting: state.alumniListState.submitting,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAlumniList, handleAlumniListPersonPageClick })(AlumniList),
);
