//@flow
import React from 'react';
// import './Candidate.css';

const ApplySuccess = () => (
  <div
    className="mainBody"
    style={{
      display: 'block',
      textAlign: 'left',
      padding: '3rem',
      marginTop: '3rem',
      overflow: 'auto',
    }}
  >
    <p>
      <strong>Terms and Conditions</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>Agreement between user and the30.com</span>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Welcome to thes30.com. The thes30.com website (the "Site") is comprised of various web pages
        operated by S30 thes30.com is offered to you conditioned on your acceptance without
        modification of the terms, conditions, and notices contained herein (the "Terms"). Your use
        of thes30.com constitutes your agreement to all such Terms. Please read these terms
        carefully, and keep a copy of them for your reference.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 helps software engineers prepare for coding interviews. We sell an online study tool.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Privacy</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Your use of thes30.com is subject to S30's Privacy Policy. Please review our Privacy Policy,
        which also governs the Site and informs users of our data collection practices.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Electronic Communications</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Visiting thes30.com or sending emails to S30 constitutes electronic communications. You
        consent to receive electronic communications and you agree that all agreements, notices,
        disclosures and other communications that we provide to you electronically, via email and on
        the Site, satisfy any legal requirement that such communications be in writing.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Your account</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        If you use this site, you are responsible for maintaining the confidentiality of your
        account and password and for restricting access to your computer, and you agree to accept
        responsibility for all activities that occur under your account or password. You may not
        assign or otherwise transfer your account to any other person or entity. You acknowledge
        that S30 is not responsible for third party access to your account that results from theft
        or misappropriation of your account. S30 and its associates reserve the right to refuse or
        cancel service, terminate accounts, or remove or edit content in our sole discretion. A
        student will have access to the account 12 months after their course start date in admit
        email. S30 Dashboard and videos access will be available to you 12 months after your cohort
        completion date.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 does not knowingly collect, either online or offline, personal information from persons
        under the age of thirteen. If you are under 18, you may use thes30.com only with permission
        of a parent or guardian.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Deferment Policy</strong>
      <br></br>
      <br></br>
      Kindly note that your admit belongs to current cohort classes and mock interviews only. We
      will not be able to give you access to subsequent cohort classes or mock interviews. If you
      wish to defer your admit then you can do so only during the first week of your admit cohort.
      S30 dashboard and videos access will be available to you 12 months after your cohort
      completion date.
    </p>
    <p>
      <strong>Admissions Interview</strong>
      <br></br>
      <br></br>
      The admissions process at S30 is to calibrate the fit of the students and answer student
      questions about schedule and logistics of the classes and mock interviews. Admissions team is
      not qualified to answer computer science related technical queries by students. If a student
      has technical questions, he/she can address the questions to jaspinder@thes30.com
    </p>
    <p>
      <strong>Cancellation/Refund Policy</strong>
      <br></br>
      <br></br>
      Kindly note that your admit belongs to current cohort classes and mock interviews only. We
      will not be able to give you access to subsequent cohort classes or mock interviews. If you
      wish to defer your admit then you can do so only during the first week of cohort. S30
      dashboard and videos access will be available to you 12 months after your cohort completion
      date
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        <p>
          a. A student who makes the initial payment and cancels before the first week of class.
        </p>
        <p>
          b. A student who request a withdrawal from the course within week 1 and week 8 of class
          receives a prorated refund of tuition if applicable. See table below.
        </p>
        <p>
          c. Student who are removed from S30 due to violations of the Code of Conduct will receive
          a prorated refund of tuition if applicable.
        </p>
         <p>Refund table</p>
        <table border="1">
          <tr>
            <th>If termination occurs </th>
            <th> S30 may keep </th>
            <th> Student Refund</th>
          </tr>
          <tr>
            <td>Prior to or during the First week </td>
            <td>0% </td>
            <td>100%</td>
          </tr>
          <tr>
            <td>During the First month</td>
            <td> 50% </td>
            <td> 50%</td>
          </tr>
          <tr>
            <td>During the Second month </td>
            <td> 75% </td>
            <td>25%</td>
          </tr>
          <tr>
            <td>After the Second month</td>
            <td> 100% </td> <td> 0%</td>
          </tr>
        </table>
        <br></br>
        <p>
          The school cannot guarantee a job to any student or graduate. S30 reserves the right to
          require students to share their offer letter for accepted jobs to seamlessly verify
          income. Referrals of any kind are not guaranteed to the students studying at S30.
        </p>
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Links to third party sites/Third party services</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        thes30.com may contain links to other websites ("Linked Sites"). The Linked Sites are not
        under the control of S30 and S30 is not responsible for the contents of any Linked Site,
        including without limitation any link contained in a Linked Site, or any changes or updates
        to a Linked Site. S30 is providing these links to you only as a convenience, and the
        inclusion of any link does not imply endorsement by S30 of the site or any association with
        its operators.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Certain services made available via thes30.com are delivered by third party sites and
        organizations. By using any product, service or functionality originating from the
        thes30.com domain, you hereby acknowledge and consent that S30 may share such information
        and data with any third party with whom S30 has a contractual relationship to provide the
        requested product, service or functionality on behalf of thes30.com users and customers.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>No unlawful or prohibited use/Intellectual Property</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        You are granted a non-exclusive, non-transferable, revocable license to access and use
        thes30.com strictly in accordance with these terms of use. As a condition of your use of the
        Site, you warrant to S30 that you will not use the Site for any purpose that is unlawful or
        prohibited by these Terms. You may not use the Site in any manner which could damage,
        disable, overburden, or impair the Site or interfere with any other party's use and
        enjoyment of the Site. You may not obtain or attempt to obtain any materials or information
        through any means not intentionally made available or provided for through the Site.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        All content included as part of the Service, such as text, graphics, logos, images, as well
        as the compilation thereof, and any software used on the Site, is the property of {'{ '}S30
        {' }'} or its suppliers and protected by copyright and other laws that protect intellectual
        property and proprietary rights. You agree to observe and abide by all copyright and other
        proprietary notices, legends or other restrictions contained in any such content and will
        not make any changes thereto.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        You will not modify, publish, transmit, reverse engineer, participate in the transfer or
        sale, create derivative works, or in any way exploit any of the content, in whole or in
        part, found on the Site. S30 content is not for resale. Your use of the Site does not
        entitle you to make any unauthorized use of any protected content, and in particular you
        will not delete or alter any proprietary rights or attribution notices in any content. You
        will use protected content solely for your personal use, and will make no other use of the
        content without the express written permission of S30 and the copyright owner. You agree
        that you do not acquire any ownership rights in any protected content. We do not grant you
        any licenses, express or implied, to the intellectual property of S30 or our licensors
        except as expressly authorized by these Terms.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Use of communication services</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        The Site may contain bulletin board services, chat areas, news groups, forums, communities,
        personal web pages, calendars, and/or other message or communication facilities designed to
        enable you to communicate with the public at large or with a group (collectively,
        "Communication Services"), you agree to use the Communication Services only to post, send
        and receive messages and material that are proper and related to the particular
        Communication Service.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        By way of example, and not as a limitation, you agree that when using a Communication
        Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal
        rights (such as rights of privacy and publicity) of others; publish, post, upload,
        distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene,
        indecent or unlawful topic, name, material or information; upload files that contain
        software or other material protected by intellectual property laws (or by rights of privacy
        of publicity) unless you own or control the rights thereto or have received all necessary
        consents; upload files that contain viruses, corrupted files, or any other similar software
        or programs that may damage the operation of another's computer; advertise or offer to sell
        or buy any goods or services for any business purpose, unless such Communication Service
        specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or
        chain letters; download any file posted by another user of a Communication Service that you
        know, or reasonably should know, cannot be legally distributed in such manner; falsify or
        delete any author attributions, legal or other proper notices or proprietary designations or
        labels of the origin or source of software or other material contained in a file that is
        uploaded, restrict or inhibit any other user from using and enjoying the Communication
        Services; violate any code of conduct or other guidelines which may be applicable for any
        particular Communication Service; harvest or otherwise collect information about others,
        including e-mail addresses, without their consent; violate any applicable laws or
        regulations.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 has no obligation to monitor the Communication Services. However, {'{ '}S30
        {' }'} reserves the right to review materials posted to a Communication Service and to
        remove any materials in its sole discretion. S30 reserves the right to terminate your access
        to any or all of the Communication Services at any time without notice for any reason
        whatsoever.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 reserves the right at all times to disclose any information as necessary to satisfy any
        applicable law, regulation, legal process or governmental request, or to edit, refuse to
        post or to remove any information or materials, in whole or in part, in {'{ '}S30
        {' }'}'s sole discretion.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Always use caution when giving out any personally identifying information about yourself or
        your children in any Communication Service. S30 does not control or endorse the content,
        messages or information found in any Communication Service and, therefore, {'{ '}
        S30{' }'} specifically disclaims any liability with regard to the Communication Services and
        any actions resulting from your participation in any Communication Service. Managers and
        hosts are not authorized S30 spokespersons, and their views do not necessarily reflect those
        of {'{ '}S30
        {' }'}.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Materials uploaded to a Communication Service may be subject to posted limitations on usage,
        reproduction and/or dissemination. You are responsible for adhering to such limitations if
        you upload the materials.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Materials provided to thes30.com or posted on any S30 web page
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 does not claim ownership of the materials you provide to thes30.com (including feedback
        and suggestions) or post, upload, input or submit to any S30 Site or our associated services
        (collectively "Submissions"). However, by posting, uploading, inputting, providing or
        submitting your Submission you are granting {'{ '}S30
        {' }'}, our affiliated companies and necessary sublicensees permission to use your
        Submission in connection with the operation of their businesses, including, without
        limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform,
        reproduce, edit, translate and reformat your Submission; and to publish your name in
        connection with your Submission. These permissions apply to present and future businesses,
        which include but are not limited to other Internet Sites, in-person training programs, and
        Books and other Publications.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        No compensation will be paid with respect to the use of your Submission, as provided herein,
        unless otherwise stated. S30 is under no obligation to post or use any Submission you may
        provide and may remove any Submission at any time in S30's sole discretion.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        By posting, uploading, inputting, providing or submitting your Submission you warrant and
        represent that you own or otherwise control all of the rights to your Submission as
        described in this section including, without limitation, all the rights necessary for you to
        provide, post, upload, input or submit the Submissions.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Third Party Accounts</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        You will be able to connect your S30 account to third party accounts. By connecting your S30
        account to your third party account, you acknowledge and agree that you are consenting to
        the continuous release of information about you to others (in accordance with your privacy
        settings on those third party sites). If you do not want information about you to be shared
        in this manner, do not use this feature.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>International Users</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        The Service is controlled, operated and administered by S30 from our offices within India.
        If you access the Service from a location outside India, you are responsible for compliance
        with all local laws. You agree that you will not use the S30 Content accessed through
        thes30.com in any country or in any manner prohibited by any applicable laws, restrictions
        or regulations.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Indemnification</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        You agree to indemnify, defend and hold harmless S30, its officers, directors, employees,
        agents and third parties, for any losses, costs, liabilities and expenses (including
        reasonable attorneys' fees) relating to or arising out of your use of or inability to use
        the Site or services, any user postings made by you, your violation of any terms of this
        Agreement or your violation of any rights of a third party, or your violation of any
        applicable laws, rules or regulations. S30 reserves the right, at its own cost, to assume
        the exclusive defense and control of any matter otherwise subject to indemnification by you,
        in which event you will fully cooperate with S30 in asserting any available defenses.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Liability disclaimer</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE
        MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
        INFORMATION HEREIN. S30, INC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN
        THE SITE AT ANY TIME.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30, INC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
        AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
        PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. S30, INC AND/OR ITS SUPPLIERS
        HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
        PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL S30, AND/OR ITS
        SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
        DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
        DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE
        SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR
        FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE,
        WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF S30, INC
        OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
        STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
        OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
        WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE
        REMEDY IS TO DISCONTINUE USING THE SITE.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Termination/access restriction</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 reserves the right, in its sole discretion, to terminate your access to the Site and the
        related services or any portion thereof at any time, without notice. To the maximum extent
        permitted by law, this agreement is governed by the laws of the State of California and you
        hereby consent to the exclusive jurisdiction and venue of courts in California in all
        disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized
        in any jurisdiction that does not give effect to all provisions of these Terms, including,
        without limitation, this section.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        You agree that no joint venture, partnership, employment, or agency relationship exists
        between you and S30 as a result of this agreement or use of the Site. {'{ '}S30
        {' }'}'s performance of this agreement is subject to existing laws and legal process, and
        nothing contained in this agreement is in derogation of S30's right to comply with
        governmental, court and law enforcement requests or requirements relating to your use of the
        Site or information provided to or gathered by S30 with respect to such use. If any part of
        this agreement is determined to be invalid or unenforceable pursuant to applicable law
        including, but not limited to, the warranty disclaimers and liability limitations set forth
        above, then the invalid or unenforceable provision will be deemed superseded by a valid,
        enforceable provision that most closely matches the intent of the original provision and the
        remainder of the agreement shall continue in effect.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style={{ fontWeight: '400' }}>
        Unless otherwise specified herein, this agreement constitutes the entire agreement between
        the user and S30 with respect to the Site and it supersedes all prior or contemporaneous
        communications and proposals, whether electronic, oral or written, between the user and S30
        with respect to the Site. A printed version of this agreement and of any notice given in
        electronic form shall be admissible in judicial or administrative proceedings based upon or
        relating to this agreement to the same extent an d subject to the same conditions as other
        business documents and records originally generated and maintained in printed form. It is
        the express wish to the parties that this agreement and all related documents be written in
        English.
      </span>
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Changes to Terms</strong>
    </p>
    <p>
      <span style={{ fontWeight: '400' }}>
        S30 reserves the right, in its sole discretion, to change the Terms under which thes30.com
        is offered. The most current version of the Terms will supersede all previous versions. S30
        encourages you to periodically review the Terms to stay informed of our updates.
      </span>
    </p>
  </div>
);

export default ApplySuccess;
