//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { render } from 'react-dom';
import axios from 'axios';
import ROOT_URL from '../../config/baseUrl';
import history from '../../history';

// const stripePromise = loadStripe('pk_live_sGTRJHYVQy3yeC33ynWAjMxL00ysY8zb9U');
const stripePromise = loadStripe(
  'pk_live_51MaEYCCIUgJDfX3U2dEmv6Vdw4c5iGFBlt0gIBLwpPSdbpVE8tboV9gVaU2yVCWb4CmnxZ9WSpYvtaD8hxmDLXJo00popNpDSv',
);

type Props = {
  noOfPendingInstallments: Number,
  totalPayment: Number,
  refereeId: String,
  paidTillNow: Number,
  nextPaymentDueDate: Date,
  passReferralBonusToIncomingStudent: Boolean,
};
const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  error: {
    backgroundColor: '#e50914',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};

class StudentPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullPayment: 0,
      redirectingToStripe: false,
    };
  }

  handleRazPayClick = async (e, fullPayment) => {
    //fullPayment 0-> current installment 1 --> full payment 2--> System Design Payment
    e.preventDefault();
    try {
      await this.setState({
        fullPayment,
        redirectingToStripe: true,
      });
      const token = localStorage.getItem('token');
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const apiurl = `${ROOT_URL}/api/razorpay-create-checkout-session`;
      let result = await axios.post(apiurl, this.state, headers);
      const { data } = result;
      await this.setState({
        redirectingToStripe: false,
      });
      const options = {
        key: process.env.RazorPay_Key_Id,
        name: 'S 30 Interview Prep Private Ltd',
        description: 'Interview Prep Batch Fee',
        amount: data.order.amount,
        order_id: data.order.id,
        handler: async response => {
          try {
            const values = {
              paymentId: response.razorpay_payment_id,
            };
            const url = `${ROOT_URL}/api/razorpayPayment`;
            const captureResponse = await axios.post(url, values, headers);
            let { currency, amount } = captureResponse.data.paymentSuccess;
            amount = amount / 100;
            alert(
              `Payment successful for ${currency} ${amount}. Please check your email for receipt.`,
            );
            history.push('/dashboard');
            window.location.reload();
          } catch (err) {
            alert(`Transaction Failed due to ${err}, Ping Jaspinder on slack/LinkedIn`);
          }
        },
        prefill: {
          name: data.order.candidateName,
          email: data.order.email,
        },
        theme: {
          color: '#000005',
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
    }
  };

  handleClick = async (e, fullPayment, billingLocation) => {
    e.preventDefault();
    try {
      await this.setState({
        fullPayment,
        redirectingToStripe: true,
      });
      const token = localStorage.getItem('token');
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      let result;

      // if (billingLocation == 1) {
      //stripe
      const apiurl = `${ROOT_URL}/api/create-checkout-session`;
      const stripe = await stripePromise;
      const session = await axios.post(apiurl, this.state, headers);
      result = await stripe.redirectToCheckout({
        sessionId: session.data.id,
      });
      // } else {
      // const apiurl = `${ROOT_URL}/api/payu-create-checkout-session`;
      // result = await axios.post(apiurl, this.state, headers);
      // if (result.status == 204) {
      //   alert(
      //     'We do not allow duplicate requests to avoid multiple charges on student account. Please try after 24 hours if your request failed',
      //   );
      // } else {
      //   window.open(result.data, '_blank');
      // }
      // }
      await this.setState({
        fullPayment: 0,
        redirectingToStripe: false,
      });
      history.push('/dashboard');
      // if (result.error) {
      //   // If `redirectToCheckout` fails due to a browser or network
      //   // error, display the localized error message to your customer
      //   // using `result.error.message`.
      // }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { candidate, classes } = this.props;
    const {
      noOfPendingInstallments,
      totalPayment,
      refereeId,
      paidTillNow,
      nextPaymentDueDate,
      passReferralBonusToIncomingStudent,
      billingLocation,
      isSystemDesignAuthorized,
    } = candidate;
    const discount = refereeId ? (passReferralBonusToIncomingStudent ? 100 : 50) : 0;
    return (
      <div className="mainBody">
        <Sidebar />
        <div className="success-stories" style={{ marginLeft: '5rem' }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.redirectingToStripe}
            autoHideDuration={6000}
            ContentProps={{
              classes: { root: classes.submit },
            }}
            message={<p>...</p>}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '20px',
              alignItems: 'flex-start',
            }}
          >
            <p style={{ fontSize: '16px' }}>
              1. Kindly ping Sowmya on slack, if fee displayed is incorrect or failing payments.
            </p>
            <p style={{ fontSize: '16px' }}>
              2. <b>If you are based in US</b> and paying with an Indian Debit/Credit Card then 18%
              extras GST will be charged over and above the mentioned amount at the end of month.
            </p>
            <p style={{ fontSize: '16px' }}>
              3. <b>If you are based in India</b> use UPI for best payment experience.
            </p>
            <p style={{ fontSize: '16px' }}>
              4. Make sure that international payments are free for your US card, call your bank in
              US to check. The payment gateway vendor currently doesn't accept Discover Cards.
              Kindly borrow your friend/relative card if you have only discover card with you.
            </p>
          </div>
          <h3>Payment Details of Current Cohort</h3>
          {totalPayment && (
            <div>
              <p>Total Fee: ${totalPayment.toFixed(2)}</p>
              <p>Already Paid Fee: ${paidTillNow.toFixed(2)}</p>
              {refereeId ? <p> Referral Discount: ${discount}</p> : null}
            </div>
          )}
          {/* <h4>
            Kindly Note that if you are based in US/Canada and paying with and Indian Debit/Credit
            Card then 18% extras GST will be charged over and above the below mentioned amount
          </h4> */}
          {/* <h5>Invalid Card/Authentication Failed Error on Stripe? --> Contact you bank</h5>
          <h5>For Students in India $ to Rs coversion is at current rate</h5> */}
          {/* <h5>
            In the country section please put the country code as US (USA or United States is not
            accepted)
          </h5> */}
          {/* <h5>
            Kindly note that S30 is based in India. So make sure international transactions are
            swithced on in your bank account. Also check with your bank to make sure your
            international transactions are free and are not charged any fee (Mostly US banks offer
            them free). Discover cards are not accepted.
          </h5> */}
          {totalPayment - paidTillNow - discount > 1 ? (
            <div className="testimonial-container">
              <div className="testimonial" style={{ margin: '2rem' }}>
                <p>
                  <b>Next Installment for Cohort</b>
                </p>
                <p>
                  Fee Due: $
                  {((totalPayment - paidTillNow - discount) / noOfPendingInstallments).toFixed(2)}
                </p>
                <p>
                  Due Date:{' '}
                  <b>
                    {moment(nextPaymentDueDate)
                      .format('MMMM Do YYYY')
                      .toString()}
                  </b>
                </p>
                <button type="submit" onClick={e => this.handleClick(e, false, billingLocation)}>
                  Pay Now
                </button> 
                {/* <button type="submit" onClick={e => this.handleRazPayClick(e, 0)}>
                  Pay Now
                </button> */}
              </div>
              <div className="testimonial" style={{ margin: '2rem' }}>
                <p>
                  <b>Pay in full for Cohort</b>
                </p>
                <p>Total Fee Due: ${(totalPayment - paidTillNow - discount).toFixed(2)}</p>
                <p>
                  Last Due Date:{' '}
                  <b>
                    {moment(nextPaymentDueDate)
                      .add(21 * (noOfPendingInstallments - 1), 'days')
                      .format('MMMM Do YYYY')
                      .toString()}
                  </b>
                </p>
                <button type="submit" onClick={e => this.handleClick(e, true, billingLocation)}>
                  Pay Securely
                </button>
                {/* <button type="submit" onClick={e => this.handleRazPayClick(e, 1)}>
                  Pay Now
                </button> */}
              </div>
              {!isSystemDesignAuthorized && (
                <div className="testimonial" style={{ margin: '2rem' }}>
                  <p>
                    <b>System Design Videos </b>
                  </p>
                  <p>Total Fee Due: $65</p>
                  <p>Request Sowmya N for couple of demo videos before paying</p>
                  <button type="submit" onClick={e => this.handleClick(e, true, billingLocation)}>
                Pay Securely
              </button>
                  {/* <button type="submit" onClick={e => this.handleRazPayClick(e, 2)}>
                    Pay Now
                  </button> */}
                </div>
              )}
            </div>
          ) : (
            <div className="testimonial-container">
              {!isSystemDesignAuthorized && (
                <div className="testimonial" style={{ margin: '2rem' }}>
                  <p>
                    <b>System Design Videos </b>
                  </p>
                  <p>Total Fee Due: $65</p>
                  <p>Request Sowmya N for couple of demo videos before paying</p>
                  <button type="submit" onClick={e => this.handleClick(e, true, billingLocation)}>
                Pay Securely
              </button>
                  {/* <button type="submit" onClick={e => this.handleRazPayClick(e, 2)}>
                    Pay Now
                  </button> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.authState.user.candidateId,
});

export default withStyles(styles)(connect(mapStateToProps)(StudentPayment));
