//@flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Sidebar from '../Sidebar/Sidebar';

import AddUserForm from './AddUserForm';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddUserValidationSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  pincode: Yup.string()
    .min(5, 'Enter a valid digit pincode')
    .max(7, 'Enter a valid digit pincode')
    .required('Required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  // phoneNumber: Yup.string()
  //   .min(10, 'Enter a valid phoneNumber')
  //   .max(13, 'Enter a valid phoneNumber')
  //   .required('Required'),
});

const initialAddUserValues = {
  username: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  phoneNumber: '',
  email: '',
  slackId: '',
  password: '',
  confirmPassword: '',
  cohortId: '',
  schoolId: '',
  paidTillNow: 0,
  totalPayment: 0,
  noOfPendingInstallments: 1,
  billingLocation: 1,
  // roleIds: [],
};
type Props = {};
const AddUser = (props: Props) => {
  return (
    <div className="mainBody">
      <Sidebar />
      <Formik
        initialValues={initialAddUserValues}
        render={props => <AddUserForm {...props} />}
        validationSchema={AddUserValidationSchema}
      />
    </div>
  );
};

export default AddUser;
