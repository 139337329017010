/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
//@flow
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';
import { connect } from 'react-redux';
import { hydrateAuthState } from '../../redux/actions';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
// $FlowFixMe.

type Props = {
  isAuthorized: Boolean,
  hydrateAuthState: Function,
};

class Header extends Component<Props, State> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const token = localStorage.getItem('token');
    let isAuthorized;
    !token ? (isAuthorized = false) : (isAuthorized = true);
    const s30Roles = localStorage.getItem('s30Roles');
    this.props.hydrateAuthState(isAuthorized, s30Roles);
  }

  render() {
    const { isAuthorized } = this.props;
    return (
      <div>
        {isAuthorized ? (
          <div className="header">
            <NavLink to="/">
              <div className="logo">
                <div className="image">
                  <div className="img" />
                </div>
                <div className="text">
                  {'{ '}S30{' }'}
                </div>
              </div>
            </NavLink>

            <div className="nav">
              <ul>
                <a
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/spreadsheets/d/1O_qwBKEESxXos-4auFjiU56RemuF2Fic--Mm7ABPvHA/edit?usp=sharing',
                      '_blank',
                    )
                  }
                >
                  <li>Curriculum</li>
                </a>

                <NavLink to="/referral">
                  <li>Referral/Earn $50</li>
                </NavLink>
                <NavLink to="/jobs">
                  <li>New Grad/Intern Positions</li>
                </NavLink>
                <NavLink to="/dashboard">
                  <li>Dashboard</li>
                </NavLink>
                <DropdownMenu />
              </ul>
            </div>
          </div>
        ) : (
          <div className="header">
            <NavLink to="/">
              <div className="logo">
                <div className="image">
                  <div className="img" />
                </div>
                <div className="text">
                  {'{ '}S30{' }'}
                </div>
              </div>
            </NavLink>
            <div className="nav">
              <ul>
                {/* <NavLink to="/jobs">
                  <li>Curriculum</li>
                </NavLink> */}
                <NavLink to="/fee">
                  <li>Course Fee</li>
                </NavLink>
                <a
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/spreadsheets/d/1O_qwBKEESxXos-4auFjiU56RemuF2Fic--Mm7ABPvHA/edit?usp=sharing',
                      '_blank',
                    )
                  }
                >
                  <li>Curriculum</li>
                </a>
                <NavLink to="/faqs">
                  <li>FAQs</li>
                </NavLink>
                <NavLink to="/signin">
                  <li>Sign In</li>
                </NavLink>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthorized: state.authState.isAuthorized,
});

export default connect(mapStateToProps, { hydrateAuthState })(Header);
