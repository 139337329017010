import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Sidebar.scss';
import React, { Component } from 'react';
import Side from './Side';
import CareerResources from '../CareerResources/CareerResources';
const list = {
  Admin: [
    { '/addcohort': 'Add Cohort' },
    { '/applicants': 'Applicants' },
    { '/interviews': 'Interviews' },
    { '/leadershipinterviews': 'Leadership Interviews' },
    { '/interviewers': 'Interviewers' },
    { '/candidates': 'Candidates' },
    { '/feedbacks?admin': 'All Feedbacks' },
    { '/leadershipfeedbacks?admin': 'All Leadership Feedbacks' },
    { '/addproblem': 'Add Problem' },
    { '/adddsproblem': 'Add DSProblem' },
    { '/recommendedcandidate': 'Add Details Candidate' },
    { '/schools': 'Schools' },
    { '/addschool': 'Add School' },
    { '/addcompany': 'Add Company' },
    { '/companies': 'Companies' },
  ],
  Interviewer: [
    { '/feedbacks?interviewer': 'Candidates Interviewed Feedbacks' },
    {
      '/performances': 'Your Rating as Interviewer',
    },
    // { '/leadershipfeedbacks?interviewer': 'Leadership Candidate Feedbacks' },
    // { '/candidates': 'Candidates' },
    { '/cohorts': 'Manage Cohorts' },
  ],
  CohortManager: [
    { '/feedbacks?cm': 'Cohort Feedbacks' },
    { '/leadershipfeedbacks?cm': 'Cohort Leadership Feedbacks' },
    { '/addVideoUrl': 'Add Problem Video Url' },
    { '/candidates': 'Candidates' },
    // { '/cohorts': 'Manage Cohorts' },
  ],
  Candidate: [
    { '/prStatus': 'Homework/Pr Status' },
    {
      '/feedbacks?candidate': 'Mock Interview Feedbacks',
    },
    { '/alumnilist': 'S30 Alumni LinkedIn' },
    { '/notes': 'System Design Notes' },
  ],
  RecommendedCandidate: [
    {
      '/myprofile': 'My Profile',
    },
  ],
};
type Props = { user: Object };
type State = { roles: string };
class Sidebar extends Component<Props, State> {
  constructor(props: {}) {
    super(props);
    this.state = { roles: localStorage.getItem('s30Roles') || '' };
  }

  render() {
    const { roles } = this.state;
    const { isRecommended } = this.props.user.candidateId || false;
    return (
      <div className="sidebar">
        <nav className="navigation">
          <ul>
            <Link to="/dashboard">
              <li>Dashboard</li>
            </Link>

            {roles.includes('Recruiter') && (
              <Link to="/recommendedCandidates">
                <li>Recommended Candidates</li>
              </Link>
            )}

            {roles.includes('Candidate') &&
              isRecommended &&
              list['RecommendedCandidate'].map((recommendedCandidate, index) => {
                return (
                  <Side
                    key={index}
                    link={Object.keys(recommendedCandidate)[0]}
                    name={Object.values(recommendedCandidate)[0]}
                  />
                );
              })}
            {(roles.includes('Interviewer') || roles.includes('CohortManager')) && (
              <>
                <Link to="/problems">
                  <li>Problems</li>
                </Link>
                <Link to="/dsproblems">
                  <li>DSProblems</li>
                </Link>
              </>
            )}
            {(roles.includes('CohortManager') || roles.includes('Candidate')) && (
              <Link to="/problemsWithUrl">
                <li>Problem Solutions and Videos</li>
              </Link>
            )}
            {roles.includes('CohortManager') &&
              list['CohortManager'].map((admin, index) => {
                return (
                  <Side key={index} link={Object.keys(admin)[0]} name={Object.values(admin)[0]} />
                );
              })}
            {roles.includes('Interviewer') &&
              list['Interviewer'].map((admin, index) => {
                return (
                  <Side key={index} link={Object.keys(admin)[0]} name={Object.values(admin)[0]} />
                );
              })}
            {roles.includes('Admin') &&
              list['Admin'].map((admin, index) => {
                return (
                  <Side key={index} link={Object.keys(admin)[0]} name={Object.values(admin)[0]} />
                );
              })}
            {roles.includes('Candidate') &&
              list['Candidate'].map((admin, index) => {
                return (
                  <Side key={index} link={Object.keys(admin)[0]} name={Object.values(admin)[0]} />
                );
              })}

            {(roles.includes('Admin') || roles.includes('CohortManager')) && (
              <Link to="/alumnilist">
                <li>Alumni </li>
              </Link>
            )}
            {(roles.includes('Candidate') || roles.includes('CohortManager')) && (
              <Link to="/kit">
                <li>Recent Interview Questions by Company</li>
              </Link>
            )}
            {(roles.includes('Candidate') || roles.includes('CohortManager')) && (
              <a
                onClick={() =>
                  window.open(
                    'https://docs.google.com/document/d/1ROqRMcb9C6ix04R-PPtFSLrX8XnYM02eQ8Kd_j4_vDE',
                    '_blank',
                  )
                }
              >
                <li>MAANG Interview Experiences</li>
              </a>
            )}
            {roles.includes('Candidate') && (
              <a
                onClick={() =>
                  window.open(
                    'https://docs.google.com/document/d/1MxAptqe2aV0UiJUgLVtvOvLqZMMH33ZcAE5uylr3Wi8',
                    '_blank',
                  )
                }
              >
                <li>Behavioural/Leadership Priciples Questions</li>
              </a>
            )}
            {roles.includes('Candidate') && (
              <a
                target="_blank"
                href="https://www.amazon.com/VIZ-PRO-Magnetic-Erase-Inches-Aluminium/dp/B086WV92KL?crid=1HBRKX8ZJV3F9&amp;keywords=whiteboard+medium+size&amp;qid=1695801633&amp;sprefix=whiteboard+medium%252Caps%252C339&amp;sr=8-5&amp;linkCode=ll1&amp;tag=jaspindersi03-20&amp;linkId=c0f08ad8cac5ca8525f717439c67e9c6&amp;language=en_US&amp;ref_=as_li_ss_tl&_encoding=UTF8&tag=jaspindersi03-20&linkCode=ur2&linkId=9dc68f40853512fabe62a30c5e796602&camp=1789&creative=9325"
              >
                <li>Preferred Whiteboard Dimensions</li>
              </a>
            )}
            <CareerResources />

            {/* <Link to="/salaries">
              <li>Salaries Offered</li>
            </Link> */}
          </ul>
        </nav>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});
export default connect(mapStateToProps)(Sidebar);
