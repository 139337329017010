//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// actions
import {
  handleProblemSearch,
  handleDSProblemSearch,
  handleProblemWithUrlSearch,
  handleCandidateSearch,
  handleCompanySearch,
  handleAlumniListCompanySearch,
  handleInterviewKitCompanySearch,
  handleInterviewFeedbackSearch,
  handleJobSearch,
  handleInterviewerSearch,
  handleApplicantSearch,
} from '../../redux/actions';
type Props = {
  allProblems: Array<Object>,
  allProblemsWithUrl: Array<Object>,
  allCandidates: Array<Object>,
  allFeedbacks: Array<Object>,
  allJobOpenings: Array<Object>,
  handleProblemSearch: Function,
  handleCompanySearch: Function,
  handleAlumniListCompanySearch: Function,
  handleProblemWithUrlSearch: Function,
  handleCandidateSearch: Function,
  handleInterviewFeedbackSearch: Function,
  handleInterviewKitCompanySearch: Function,
  handleInterviewerSearch: Function,
  handleJobSearch: Function,
  handleApplicantSearch: Function,
  type: string,
  placeHolder: string,
};
type State = {
  dsproblemSearchByName: string,
  problemSearchByName: string,
  problemSearchByCompany: string,
  problemSearchByTechnique: string,
  problemWithUrlSearchByName: string,
  problemWithUrlSearchByCompany: string,
  problemWithUrlSearchByTechnique: string,
  candidateSearchByName: string,
  candidateSearchByCohort: string,
  feedbackSearchByInterviewer: string,
  feedbackSearchByCandidate: string,
  jobsSearchByType: string,
  jobsSearchByCompany: string,
  interviewerSearchByName: string,
  applicantSearchByName: string,
  companySearchByName: string,
  alumniListCompanySearchByName: string,
  interviewKitCompanySearchByName: string,
};
class Searchbar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      dsproblemSearchByName: '',
      problemSearchByName: '',
      problemSearchByCompany: '',
      problemSearchByTechnique: '',
      problemWithUrlSearchByName: '',
      problemWithUrlSearchByCompany: '',
      problemWithUrlSearchByTechnique: '',
      candidateSearchByName: '',
      candidateSearchByCohort: '',
      jobsSearchByType: '',
      jobsSearchByCompany: '',
      feedbackSearchByInterviewer: '',
      feedbackSearchByCandidate: '',
      interviewerSearchByName: '',
      applicantSearchByName: '',
      companySearchByName: '',
      alumniListCompanySearchByName: '',
      interviewKitCompanySearchByName: '',
    };
  }

  handleSearch = async (e, type) => {
    e.preventDefault();
    await this.setState({
      [type]: e.target.value,
    });
    if (type === 'dsproblemSearchByName') {
      const probReGex = new RegExp(this.state.dsproblemSearchByName, 'i');
      let filtereddsProblems = this.props.allDSProblems.filter(dsproblem => {
        if (dsproblem.name.search(probReGex) !== -1) {
          return dsproblem;
        } else {
          return null;
        }
      });
      this.props.handleDSProblemSearch(filtereddsProblems);
    }
    if (type === 'problemSearchByName') {
      const probReGex = new RegExp(this.state.problemSearchByName, 'i');
      let filteredProblems = this.props.allProblems.filter(problem => {
        if (problem.name.search(probReGex) !== -1) {
          return problem;
        } else {
          return null;
        }
      });
      this.props.handleProblemSearch(filteredProblems);
    }
    if (type === 'problemSearchByCompany') {
      const probReGex = new RegExp(this.state.problemSearchByCompany, 'i');
      let filteredProblems = this.props.allProblems.filter(problem => {
        if (problem.companyTags.search(probReGex) !== -1) {
          return problem;
        } else {
          return null;
        }
      });
      this.props.handleProblemSearch(filteredProblems);
    }
    if (type === 'problemSearchByTechnique') {
      const probReGex = new RegExp(this.state.problemSearchByTechnique, 'i');
      let filteredProblems = this.props.allProblems.filter(problem => {
        if (problem.techniques.search(probReGex) !== -1) {
          return problem;
        } else {
          return null;
        }
      });
      this.props.handleProblemSearch(filteredProblems);
    }
    if (type === 'problemWithUrlSearchByName') {
      const probReGex = new RegExp(this.state.problemWithUrlSearchByName, 'i');
      let filteredProblemsWithUrl = this.props.allProblemsWithUrl.filter(problemWithUrl => {
        if (problemWithUrl.problemId.name.search(probReGex) !== -1) {
          return problemWithUrl;
        } else {
          return null;
        }
      });
      this.props.handleProblemWithUrlSearch(filteredProblemsWithUrl);
    }
    if (type === 'problemWithUrlSearchByCompany') {
      const probReGex = new RegExp(this.state.problemWithUrlSearchByCompany, 'i');
      let filteredProblemsWithUrl = this.props.allProblemsWithUrl.filter(problemWithUrl => {
        if (problemWithUrl.problemId.companyTags.search(probReGex) !== -1) {
          return problemWithUrl;
        } else {
          return null;
        }
      });
      this.props.handleProblemWithUrlSearch(filteredProblemsWithUrl);
    }
    if (type === 'problemWithUrlSearchByTechnique') {
      const probReGex = new RegExp(this.state.problemWithUrlSearchByTechnique, 'i');
      let filteredProblemsWithUrl = this.props.allProblemsWithUrl.filter(problemWithUrl => {
        if (problemWithUrl.problemId.techniques.search(probReGex) !== -1) {
          return problemWithUrl;
        } else {
          return null;
        }
      });
      this.props.handleProblemWithUrlSearch(filteredProblemsWithUrl);
    }
    if (type === 'candidateSearchByName') {
      const candidateReGex = new RegExp(this.state.candidateSearchByName, 'i');
      let filteredCandidates = this.props.allCandidates.filter(candidate => {
        if (
          candidate.userId.firstName.search(candidateReGex) !== -1 ||
          candidate.userId.lastName.search(candidateReGex) !== -1
        ) {
          return candidate;
        } else {
          return null;
        }
      });
      this.props.handleCandidateSearch(filteredCandidates);
    }
    if (type === 'jobsSearchByType') {
      const jobOpeningReGex = new RegExp(this.state.jobsSearchByType, 'i');
      let filteredJobOpenings = this.props.allJobOpenings.filter(jobOpening => {
        if (jobOpening.Type.search(jobOpeningReGex) !== -1) {
          return jobOpening;
        } else {
          return null;
        }
      });
      this.props.handleJobSearch(filteredJobOpenings);
    }
    if (type === 'jobsSearchByCompany') {
      const jobOpeningReGex = new RegExp(this.state.jobsSearchByCompany, 'i');
      let filteredJobOpenings = this.props.allJobOpenings.filter(jobOpening => {
        if (jobOpening.Company.search(jobOpeningReGex) !== -1) {
          return jobOpening;
        } else {
          return null;
        }
      });
      this.props.handleJobSearch(filteredJobOpenings);
    }
    if (type === 'candidateSearchByCohort') {
      const candidateReGex = new RegExp(this.state.candidateSearchByCohort, 'i');
      let filteredCandidates = this.props.allCandidates.filter(candidate => {
        if (candidate.cohortId.name.search(candidateReGex) !== -1) {
          return candidate;
        } else {
          return null;
        }
      });
      this.props.handleCandidateSearch(filteredCandidates);
    }
    if (type === 'feedbackSearchByInterviewer') {
      const feedbackReGex = new RegExp(this.state.feedbackSearchByInterviewer, 'i');
      let filteredFeedbacks = this.props.allFeedbacks.filter(feedback => {
        if (
          feedback.interviewerId.userId.firstName.search(feedbackReGex) !== -1 ||
          feedback.interviewerId.userId.lastName.search(feedbackReGex) !== -1
        ) {
          return feedback;
        } else {
          return null;
        }
      });
      this.props.handleInterviewFeedbackSearch(filteredFeedbacks);
    }

    if (type === 'feedbackSearchByCandidate') {
      const feedbackReGex = new RegExp(this.state.feedbackSearchByCandidate, 'i');
      let filteredFeedbacks = this.props.allFeedbacks.filter(feedback => {
        if (
          feedback.candidateId.userId.firstName.search(feedbackReGex) !== -1 ||
          feedback.candidateId.userId.lastName.search(feedbackReGex) !== -1
        ) {
          return feedback;
        } else {
          return null;
        }
      });
      this.props.handleInterviewFeedbackSearch(filteredFeedbacks);
    }
    if (type === 'companySearchByName') {
      const companyRegEx = new RegExp(this.state.companySearchByName, 'i');
      let filteredCompanies = this.props.allCompanies.filter(company => {
        if (company.name.search(companyRegEx) !== -1 || company.name.search(companyRegEx) !== -1) {
          return company;
        } else {
          return null;
        }
      });
      this.props.handleCompanySearch(filteredCompanies);
    }

    if (type === 'alumniListCompanySearchByName') {
      const companyRegEx = new RegExp(this.state.alumniListCompanySearchByName, 'i');
      let filteredCompanies = this.props.allAlumniListCompanies.filter(company => {
        if (company.name.search(companyRegEx) !== -1) {
          return company;
        } else {
          return null;
        }
      });
      this.props.handleAlumniListCompanySearch(filteredCompanies);
    }
    if (type === 'interviewKitCompanySearchByName') {
      const companyRegEx = new RegExp(this.state.interviewKitCompanySearchByName, 'i');
      let filteredCompanies = this.props.allInterviewKitCompanies.filter(company => {
        if (company.name.search(companyRegEx) !== -1) {
          return company;
        } else {
          return null;
        }
      });
      this.props.handleInterviewKitCompanySearch(filteredCompanies);
    }

    if (type === 'interviewerSearchByName') {
      const interviewerReGex = new RegExp(this.state.interviewerSearchByName, 'i');
      let filteredInterviewers = this.props.allInterviewers.filter(interviewer => {
        if (
          interviewer.userId.firstName.search(interviewerReGex) !== -1 ||
          interviewer.userId.lastName.search(interviewerReGex) !== -1
        ) {
          return interviewer;
        } else {
          return null;
        }
      });
      this.props.handleInterviewerSearch(filteredInterviewers);
    }

    if (type === 'applicantSearchByName') {
      const applicantRegEx = new RegExp(this.state.applicantSearchByName, 'i');
      let filteredApplicants = this.props.allApplicants.filter(applicant => {
        if (
          applicant.firstName.search(applicantRegEx) !== -1 ||
          applicant.lastName.search(applicantRegEx) !== -1
        ) {
          return applicant;
        } else {
          return null;
        }
      });
      this.props.handleApplicantSearch(filteredApplicants);
    }
  };
  render() {
    const { type, placeHolder } = this.props;
    return (
      <div>
        <input
          type="text"
          onChange={e => this.handleSearch(e, type)}
          style={{
            width: '100%',
            height: '30px',
            paddingLeft: '5px',
            fontSize: '16px',
            boxSizing: 'border-box',
            border: 'none',
            marginTop: '2rem',
          }}
          placeholder={placeHolder}
        />
      </div>
    );
  }
}
// const Searchbar = props => {
//   return (
//     <div>
//       <input
//         type="text"
//         onChange={e => props.handleProblemSearch(e)}
//         style={{
//           width: '100%',
//           height: '30px',
//           paddingLeft: '5px',
//           fontSize: '16px',
//           boxSizing: 'border-box',
//           border: 'none',
//         }}
//         placeholder="Search"
//       />
//     </div>
//   );
// };

const mapStateToProps = state => ({
  allProblems: state.problemState.allProblems,
  allDSProblems: state.dsproblemState.allDSProblems,
  allProblemsWithUrl: state.problemWithUrlState.allProblemsWithUrl,
  allCandidates: state.candidateState.allCandidates,
  allFeedbacks: state.feedbackState.allFeedbacks,
  allJobOpenings: state.jobOpeningState.allJobOpenings,
  allInterviewers: state.interviewerState.allInterviewers,
  allApplicants: state.applicantState.allApplicants,
  allCompanies: state.companyState.allCompanies,
  allAlumniListCompanies: state.alumniListState.allAlumniListCompanies,
  allInterviewKitCompanies: state.interviewKitState.allInterviewKitCompanies,
});

export default connect(mapStateToProps, {
  handleProblemSearch,
  handleDSProblemSearch,
  handleJobSearch,
  handleProblemWithUrlSearch,
  handleCandidateSearch,
  handleInterviewFeedbackSearch,
  handleInterviewerSearch,
  handleApplicantSearch,
  handleCompanySearch,
  handleAlumniListCompanySearch,
  handleInterviewKitCompanySearch,
})(Searchbar);
