import React from 'react';
import { ChatWidget } from '@papercups-io/chat-widget';

const Chatwidget = () => {
  return (
    <>
      {/*
        Put <ChatWidget /> at the bottom of whatever pages you would
        like to render the widget on, or in your root/router component
        if you would like it to render on every page
      */}
      <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="318fb635-c802-4646-9110-89b3c20eca31"
        token="318fb635-c802-4646-9110-89b3c20eca31"
        inbox="9c0d16e7-d2a4-4a3e-b973-387d7913ed02"
        title="Welcome to S 30 Interview Prep Private Ltd"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#abb8c3"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={false}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />
    </>
  );
};

export default Chatwidget;
