//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Interview from './Interview';
import './candidate.scss';
// $FlowFixMe.
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';

import ROOT_URL from '../../config/baseUrl';
import axios from 'axios';

//Socket
// import Socket from 'socket.io-client';

const styles = {
  submit: {
    backgroundColor: '#009688',
    color: 'white',
    fontSize: 16,
    margin: 30,
  },
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = { user: Object, getUpdatedUser: Function };
class CandidateDashboard extends Component<Props> {
  constructor() {
    super();
    this.state = {
      submitting: false,
    };
  }
  requestAlumnInterview = async e => {
    e.preventDefault();
    const apiurl = `${ROOT_URL}/api/requestAlumnInterview`;
    const token = localStorage.getItem('token');
    const headers = {
      headers: {
        Authorization: token,
      },
    };
    try {
      if (confirm(`Please confirm if you wish to request for alumni mock interview`)) {
        this.setState({ submitting: true });
        await axios.put(apiurl, {}, headers);
        setTimeout(() => {
          this.setState({ submitting: false });
        }, 1000);
        alert('Requested Interview, Kindly check slack id for schedule on Saturday Evening');
      }
    } catch (error) {
      console.log('error in requesting interview', error);
    }
  };
  render() {
    const { submitting } = this.state;
    const { user, classes } = this.props;
    var date = new Date();
    const monthsdifference = moment(new Date(date)).diff(new Date(user.createdAt), 'months', true);
    const isAlum = monthsdifference >= 3.5 ? true : false;
    const isFresher = monthsdifference < 2 ? true : false;
    let timeslot = {};
    let userId = {};
    if (
      user.candidateId != undefined &&
      user.candidateId.assignedInterviewer != null &&
      user.candidateId.isScheduled
    ) {
      timeslot = user.candidateId.assignedInterviewer.timeslot;
      userId = user.candidateId.assignedInterviewer.interviewerId.userId;
    }
    return (
      <div className="candidateDashBoard">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={submitting}
          autoHideDuration={6000}
          ContentProps={{
            classes: { root: classes.submit },
          }}
          message={<p>Submitting...</p>}
        />
        <div className="table">
          {isFresher && <h3 className="title">Student Success Panel</h3>}
          <p></p>
          {isFresher && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '40px',
                alignItems: 'flex-start',
              }}
            >
              <p style={{ fontSize: '14px', margin: '8px' }}>
                1. How to submit Precourse, Pull Requests and resolving common errors?
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/1c134467fd55468c9ef5e752240915a8?sid=2dac3bbb-4031-40fe-b2a8-c143596a92dd`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>

              <p style={{ fontSize: '14px', margin: '8px' }}>
                2. How to get expert feedback on Resume and LinkedIn from S30?
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/7e8f649836ee492ea3ffe55e4f323462?sid=6815df0b-6372-4c32-b366-bafeb151ad93`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>
              <p style={{ fontSize: '14px', margin: '8px' }}>
                3. How to attend Live Lectures and access recorded videos?
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/84088494678a49f9b968bbe732ace2a1?sid=2d33f347-2ab0-45c6-9fdd-e581e28d1468`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>
              <p style={{ fontSize: '14px', margin: '8px' }}>
                4. Everything about Mock Interviews
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/eaf560273071481db59da77dcc30031f?sid=c1d3cc0a-befa-4d08-bd9a-658ce45a5c09`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me </>
                </a>
                <a
                  target="_blank"
                  href="https://www.amazon.com/VIZ-PRO-Magnetic-Erase-Inches-Aluminium/dp/B086WV92KL?crid=1HBRKX8ZJV3F9&amp;keywords=whiteboard+medium+size&amp;qid=1695801633&amp;sprefix=whiteboard+medium%252Caps%252C339&amp;sr=8-5&amp;linkCode=ll1&amp;tag=jaspindersi03-20&amp;linkId=c0f08ad8cac5ca8525f717439c67e9c6&amp;language=en_US&amp;ref_=as_li_ss_tl&_encoding=UTF8&tag=jaspindersi03-20&linkCode=ur2&linkId=9dc68f40853512fabe62a30c5e796602&camp=1789&creative=9325"
                >
                  Preferred Whiteboard Dimensions (Bought? Provide feedback)
                </a>
              </p>
              <p style={{ fontSize: '14px', margin: '8px' }}>
                5. How/When to pay fee?
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/802c9a7a7b33424c85f337f137f90dac?sid=24afc228-91b4-48d1-8288-81237c48724a`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>
              <p style={{ fontSize: '14px', margin: '8px' }}>
                6. Interview Experiences and Questions Asked By Company
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/82dd14a456584b6bb5a8727334704986?sid=8b8f8d0d-1a67-4e7a-8a6c-76ad1363683a`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>
              <p style={{ fontSize: '14px', margin: '8px' }}>
                7. How to get Referrals from S30?
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://www.loom.com/share/1663c38e7121444aa7e36bde04341554?sid=6e281929-6674-4bb5-9a5f-59229f3f8064`,
                      '_blank',
                    )
                  }
                >
                  <> Click Me</>
                </a>
              </p>
            </div>
          )}

          {isAlum && (
            <div>
              <h4 className="title">Request Alumni Interview</h4>
              <h5 style={{ fontSize: '14px' }}>
                First Read Alumni Mock interview
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      `https://docs.google.com/document/d/17wV3h90NIGaZYD6Mmg3K1_g7FnvvD0HkpFOtn7WSsh8`,
                      '_blank',
                    )
                  }
                >
                  <> Instructions</>
                </a>
              </h5>
              <button style={{ margin: 30 }} onClick={e => this.requestAlumnInterview(e)}>
                Request Mock Interview
              </button>
            </div>
          )}
          <h4 className="title">Upcoming Interview Schedule</h4>
          {user.candidateId != undefined &&
          user.candidateId.assignedInterviewer != null &&
          user.candidateId.isScheduled ? (
            <>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  {' '}
                  Please make sure that your slack id is <b>{user.slackId}</b> so that interviewer
                  can find you on slack.
                </li>
                <li>
                  Time mentioned for interview is your system (Laptop/Mobile) time. It is <b>not</b>{' '}
                  pacific time.
                </li>
              </ul>
              <div className="thead">
                <div className="columnLarge">Interviewer Name</div>
                <div className="columnMedium">Slack Id</div>
                <div className="columnMedium">Date</div>
                <div className="columnMedium">Start Time</div>
                <div className="columnMedium">End Time</div>
              </div>
              <Interview
                interviewerName={userId.firstName + ' ' + userId.lastName}
                interviewerSlackId={userId.slackId}
                date={moment(timeslot.startTime)
                  .format('ddd MMM D, YYYY')
                  .toString()}
                startTime={moment(timeslot.startTime)
                  .format('hh : mm A')
                  .toString()}
                endTime={moment(timeslot.endTime)
                  .format('hh : mm A')
                  .toString()}
              />
            </>
          ) : (
            <h5>No Interviews Pending</h5>
          )}
        </div>
        <b></b>
        <h4>
          Get into the right mindset for Tech Interviews with O(1) Book
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`https://www.amazon.com/dp/B0C9SC74SY`, '_blank')}
          >
            <></> US Edition
          </a>
          <> and</>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`https://www.amazon.in/dp/B0CFCKLKZ3`, '_blank')}
          >
            <></> India Edition
          </a>
        </h4>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});

export default withStyles(styles)(connect(mapStateToProps)(CandidateDashboard));
