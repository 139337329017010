import React from 'react';
import './App.scss';
// Routers
import { Router, Switch, Route } from 'react-router-dom';
// Redux
import { Provider } from 'react-redux';

// Common Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
// Views for Authentication 
 import Signin from './components/Auth/Signin';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ForgotPasswordSuccess from './components/Auth/FogotPasswordSuccess';
import ResetPasswordSuccess from './components/Auth/ResetPasswordSuccess';

// History
import history from './history';

// Components
import AddProblem from './components/Problem/AddProblem';
import AddDSProblem from './components/DSProblem/AddDSProblem';
import AddCohort from './components/Cohorts/AddCohort';
import AddCandidate from './components/Candidate/AddCandidate';
import AddUser from './components/Auth/AddUser';
import AddFeedback from './components/InterviewFeedback/AddFeedback';
import AddLeadershipFeedback from './components/LeadershipInterviewFeedback/AddLeadershipFeedback';
import Candidates from './components/Candidate/Candidates';
import Feedbacks from './components/InterviewFeedback/Feedbacks';
import LeadershipFeedbacks from './components/LeadershipInterviewFeedback/LeadershipFeedbacks';
import FeedbackForm from './components/InterviewFeedback/FeedbackForm';
import LeadershipFeedbackForm from './components/LeadershipInterviewFeedback/LeadershipFeedbackForm';
import Interviewers from './components/Interviewer/Interviewers';
import Interviews from './components/Interview/Interviews';
import LeadershipInterviews from './components/Interview/LeadershipInterviews';
import UpdateLeadershipFeedback from './components/LeadershipInterviewFeedback/UpdateLeadershipFeedback';
import UpdateFeedback from './components/InterviewFeedback/UpdateFeedback';
import UpdateCandidate from './components/Candidate/UpdateCandidate';
import AddSchool from './components/School/AddSchool';
import AddCompany from './components/Company/AddCompany';
import Cohorts from './components/Cohorts/Cohorts';
import Dashboard from './components/Dashboard/Dashboard';
import Landing from './components/Landing/Landing';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Problems from './components/Problem/Problems';
import DSProblems from './components/DSProblem/DSProblems';
import ProblemsWithUrlFormik from './components/Problem/ProblemsWithUrlFormik';
import Terms from './components/Terms&Privacy/Terms';
import Privacy from './components/Terms&Privacy/Privacy';
import Contact from './components/Terms&Privacy/Contact';
import Refund from './components/Terms&Privacy/Refund';
import Schools from './components/School/Schools';
import Companies from './components/Company/Companies';
import Unsubscribe from './components/Candidate/Unsubscribe';
import ViewProblem from './components/Problem/ViewProblem';
import ViewDSProblem from './components/DSProblem/ViewDSProblem';
import UpdateProblem from './components/Problem/UpdateProblem';
import UpdateDSProblem from './components/DSProblem/UpdateDSProblem';
import UpdateProblemUrl from './components/Problem/UpdateProblemUrl';
import Unsubscribesuccess from './components/Candidate/Unsubscribesuccess';
import Faqs from './components/Faq/Faqs';
import AddVideoUrl from './components/AddVideoUrl/AddVideoUrl';
import CompanyList from './components/InterviewKit/CompanyList';
import CompanyQuestions from './components/InterviewKit/CompanyQuestions';
import CompanyQuestion from './components/InterviewKit/CompanyQuestion';
import AddInterviewQuestion from './components/InterviewKit/AddInterviewQuestion';
import JobOpenings from './components/JobOpening/JobOpenings';
import RecentJobOpenings from './components/JobOpening/RecentJobOpenings';
import AddJobOpening from './components/JobOpening/AddJobOpening';
import AlumniCompanyList from './components/AlumniList/AlumniCompanyList';
import AlumniList from './components/AlumniList/AlumniList';
import AddAlumni from './components/AlumniList/AddAlumni';

import { Notifications } from 'react-push-notification';

import GetInterviews from './components/GetInterviews/GetInterviews';
import ResumeTips from './components/ResumeTips/ResumeTips';
import LinkedInTips from './components/LinkedInTips/LinkedInTips';
import NegotiationTips from './components/NegotiationTips/NegotiationTips';
// Loading
// import CircularLoad from './components/Loading/CircularLoad';

// Pricing and Payments
import Pricings from './components/Pricing/Pricings';
import Payments from './components/Payment/Payments';
import LegacyPayments from './components/LegacyPayment/LegacyPayments';

//System Design notes
import SystemDesignNotes from './components/SystemDesignNotes/SystemDesignNotes';

// RecommendedCandidate
import RecommendedCandidates from './components/RecommendedCandidate/RecommendedCandidates';
import UpdateRecommendedCandidate from './components/RecommendedCandidate/UpdateRecommendedCandidate';

// CandidateProfile
import CandidateProfile from './components/Candidate/CandidateProfile/CandidateProfile';
import CandidateProfileUpdatedSuccess from './components/Candidate/CandidateProfile/CandidateProfileUpdatedSuccess';

//Applicant
import AddApplicant from './components/Applicant/AddApplicant';
import ApplySuccess from './components/Applicant/ApplySuccess';

// Stripe
import StripeCheckout from './components/StripeCheckout/StripeCheckout';
import StripeIndiaCheckout from './components/StripeCheckout/StripeIndiaCheckout';
import TimePicker from './components/TimePicker/TimePicker';
//PrStatus
import PrStatus from './components/PrStatus/PrStatus';

// Referral
import Referral from './components/Referral/Referral';
import ReferralSuccess from './components/Referral/ReferralSuccess';

//Salaries

import SalaryCompanyList from './components/Salary/CompanyList';
import AddSalary from './components/Salary/AddSalary';
import Applicants from './components/Applicant/Applicants';

import StudentPayment from './components/StudentPayment/StudentPayment';
import StudentPaymentSuccess from './components/StudentPayment/StudentPaymentSuccess';
import PayUStudentPaymentSuccess from './components/StudentPayment/PayUStudentPaymentSuccess';
import StudentPaymentCancelled from './components/StudentPayment/StudentPaymentCancelled';
import PayUStudentPaymentFail from './components/StudentPayment/PayUStudentPaymentFail';
// import CompanyQuestions from './components/InterviewKit/CompanyQuestions';
// import CompanyQuestion from './components/InterviewKit/CompanyQuestion';
// import AddInterviewQuestion from './components/InterviewKit/AddInterviewQuestion';
import RecruiterDataCompanies from './components/RecruiterData/RecruiterDataCompanies';
import RecruiterDataByCompany from './components/RecruiterData/RecruiterDataByCompany';
//onr
import store from './redux/store';
import AddPerformance from './components/InterviewerPerformance/AddPerformance';
import Performances from './components/InterviewerPerformance/Performances';
import PerformanceForm from './components/InterviewerPerformance/PerformanceForm';

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <div className="App">
        <Notifications />
        <Header />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route path="/reset" component={ResetPassword} />
          <Route exact path="/fee" component={Pricings} />
          {/* <Route exact path="/payment" component={Payments} /> */}
          <Route exact path="/legacyPayment" component={LegacyPayments} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/refund" component={Refund} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/apply" component={AddApplicant} />
          <Route path="/applySuccess" component={ApplySuccess} />
          <Route path="/profileSuccess" component={CandidateProfileUpdatedSuccess} />
          <Route path="/forgotPasswordSuccess" component={ForgotPasswordSuccess} />
          <Route path="/resetPasswordSuccess" component={ResetPasswordSuccess} />
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="/applySuccess" component={StripeCheckout} />
          <Route exact path="/checkout/:id" component={StripeCheckout} />
          <Route exact path="/checkoutIndia/:id" component={StripeIndiaCheckout} /> */}
          <Route exact path="/unsubscribesuccess" component={Unsubscribesuccess} />
          <Route exact path="/problem/update/:id" component={UpdateProblem} />
          <Route exact path="/dsproblem/update/:id" component={UpdateDSProblem} />
          <Route exact path="/problemWithUrl/update/:id" component={UpdateProblemUrl} />
          <Route exact path="/problem/:id" component={ViewProblem} />
          <PrivateRoute exact path="/dsproblem/:id" component={ViewDSProblem} />
          <PrivateRoute exact path="/feedback/:id" component={FeedbackForm} />
          {/* <PrivateRoute exact path="/performance/:id" component={FeedbackForm} /> */}
          <PrivateRoute exact path="/leadershipfeedback/:id" component={LeadershipFeedbackForm} />
          <PrivateRoute exact path="/feedback/update/:id" component={UpdateFeedback} />
          <PrivateRoute
            exact
            path="/leadershipfeedback/update/:id"
            component={UpdateLeadershipFeedback}
          />
          <PrivateRoute exact path="/cohorts" component={Cohorts} />
          <PrivateRoute exact path="/companies" component={Companies} />
          <PrivateRoute exact path="/addcompany" component={AddCompany} />
          <PrivateRoute exact path="/addcandidate" component={AddCandidate} />
          <PrivateRoute path="/adduser" component={AddUser} />
          <PrivateRoute exact path="/addfeedback" component={AddFeedback} />
          <PrivateRoute exact path="/performance/:id" component={PerformanceForm} />
          <PrivateRoute exact path="/performances" component={Performances} />
          <PrivateRoute exact path="/addperformance/:id/:fid" component={AddPerformance} />
          <PrivateRoute exact path="/addleadershipfeedback" component={AddLeadershipFeedback} />
          <Route exact path="/candidate/update/:id" component={UpdateCandidate} />
          <PrivateRoute exact path="/candidates" component={Candidates} />
          <PrivateRoute exact path="/interviewers" component={Interviewers} />
          <Route exact path="/interviewer/:id" component={TimePicker} />
          <PrivateRoute exact path="/interviews" component={Interviews} />
          <PrivateRoute exact path="/leadershipinterviews" component={LeadershipInterviews} />
          <PrivateRoute path="/feedbacks" component={Feedbacks} />
          <PrivateRoute path="/leadershipfeedbacks" component={LeadershipFeedbacks} />
          <PrivateRoute exact path="/problems" component={Problems} />
          <PrivateRoute exact path="/addproblem" component={AddProblem} />
          <PrivateRoute exact path="/dsproblems" component={DSProblems} />
          <PrivateRoute exact path="/adddsproblem" component={AddDSProblem} />
          <PrivateRoute exact path="/schools" component={Schools} />
          <PrivateRoute exact path="/addschool" component={AddSchool} />
          <PrivateRoute exact path="/kit" component={CompanyList} />
          <PrivateRoute exact path="/kit/:id" component={CompanyQuestions} />
          <PrivateRoute exact path="/kit/:id/:id" component={CompanyQuestion} />
          <PrivateRoute exact path="/addkit" component={AddInterviewQuestion} />
          <PrivateRoute exact path="/salaries" component={SalaryCompanyList} />
          <PrivateRoute exact path="/addSalary" component={AddSalary} />
          <PrivateRoute exact path="/addcohort" component={AddCohort} />
          <PrivateRoute exact path="/addVideoUrl" component={AddVideoUrl} />
          <PrivateRoute exact path="/problemsWithUrl" component={ProblemsWithUrlFormik} />
          {/* <PrivateRoute exact path="/jobs" component={JobOpenings} /> */}
          <PrivateRoute exact path="/addjob" component={AddJobOpening} />
          <PrivateRoute exact path="/recentJobs" component={RecentJobOpenings} />
          <PrivateRoute exact path="/alumnilist" component={AlumniCompanyList} />
          <PrivateRoute exact path="/alumnilist/:id" component={AlumniList} />
          <PrivateRoute exact path="/addalumni" component={AddAlumni} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/jobs" component={JobOpenings} />
          <PrivateRoute exact path="/notes" component={SystemDesignNotes} />
          <PrivateRoute exact path="/recommendedCandidates" component={RecommendedCandidates} />
          <PrivateRoute exact path="/myprofile" component={CandidateProfile} />
          <Route
            exact
            path="/recommendedCandidate/update/:id"
            component={UpdateRecommendedCandidate}
          />
          <PrivateRoute exact path="/(dashboard|schools|addschool)" component={Dashboard} />
          <PrivateRoute exact path="/gettingInterviews" component={GetInterviews} />
          <PrivateRoute exact path="/resumetips" component={ResumeTips} />
          <PrivateRoute exact path="/linkedintips" component={LinkedInTips} />
          <PrivateRoute exact path="/negotiationtips" component={NegotiationTips} />
          <PrivateRoute exact path="/recruiter/companies" component={RecruiterDataCompanies} />
          <PrivateRoute exact path="/recruiters/:id" component={RecruiterDataByCompany} />
          <PrivateRoute exact path="/prStatus" component={PrStatus} />
          <PrivateRoute exact path="/referral" component={Referral} />
          <PrivateRoute exact path="/referralSuccess" component={ReferralSuccess} />
          <PrivateRoute exact path="/studentPayment" component={StudentPayment} />
          <PrivateRoute
            exact
            path="/paymentcancel/:sessionId"
            component={StudentPaymentCancelled}
          />
          <PrivateRoute exact path="/paymentsuccess/:sessionId" component={StudentPaymentSuccess} />
          <Route exact path="/payupaymentsuccess" component={PayUStudentPaymentSuccess} />
          <Route exact path="/payupaymentfail" component={PayUStudentPaymentFail} />
          <PrivateRoute exact path="/applicants" component={Applicants} />
        </Switch>
        <Footer />
      </div>
    </Router>
  </Provider>
);

export default App;
