//@flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteProblemById } from '../../redux/actions';
import './Problem.scss';

type Props = {
  index: number,
  name: string,
  difficultyLevel: string,
  techniques: string,
  companyTags: string,
  problems: Array<Object>,
  deleteProblemById: Function,
  id: number,
};
// const index = 1;
const Problem = (props: Props) => (
  <div className="tbody">
    <div className="row">
      <Link to={`/problem/${props.id}`} key={props.id} className="link">
        <div className="columnSmall">{props.index}</div>
      </Link>
      <Link to={`/problem/${props.id}`} key={props.id} className="link">
        <div className="columnLarge">{props.name}</div>
      </Link>

      <Link to={`/problem/${props.id}`} key={props.id} className="link">
        <div className="columnMedium">{props.techniques}</div>
      </Link>
      <Link to={`/problem/${props.id}`} key={props.id} className="link">
        <div className="columnMedium">{props.companyTags}</div>
      </Link>

      <div className="columnAction">
        {props.videoUrl && (
          <button
            style={{
              backgroundColor: 'red',
            }}
            onClick={() => window.open(`${props.videoUrl}`, '_blank')}
            type="submit"
          >
            Video
          </button>
        )}
      </div>
      {(localStorage.getItem('s30Roles') || {}).includes('Admin') && (
        <div className="columnAction">
          <Link to={`/problem/update/${props.id}`} key={props.id}>
            <button type="submit">Edit</button>
          </Link>
        </div>
      )}
      {(localStorage.getItem('s30Roles') || {}).includes('CohortManager') && props.videoUrl && (
        <div className="columnAction">
          <Link
            to={`/problemWithUrl/update/${props.problemWithUrlId}`}
            key={props.problemWithUrlId}
          >
            <button type="submit">Edit</button>
          </Link>
        </div>
      )}
      {/* {(localStorage.getItem('s30Roles') || {}).includes('Admin') && (
        <div className="columnAction">
          <button
            type="submit"
            onClick={e => {
              e.preventDefault();
              props.deleteProblemById(props.problems, props.id);
            }}
          >
            Delete
          </button>
        </div>
      )} */}
    </div>
  </div>
);

const mapStateToProps = state => ({
  problems: state.problemState.allProblems,
});

export default connect(mapStateToProps, { deleteProblemById })(Problem);
