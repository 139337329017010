//@flow
import React from 'react';

// components
import Features from '../Features/Features';
import './Landing.css';
import Chatwidget from '../ChatWidget/ChatWidget';
import Testimonials from '../Testimonial/Testimonials';
import TeamMembers from '../Team/TeamMembers';

import { Link } from 'react-router-dom';

const Landing = () => (
  <div className="Landing">
    <div className="Intro__content">
      <Chatwidget />
      <div className="Intro__content__summary">Prepare for FAANMG SWE and Data Role Interviews</div>
      <h4>
        Life Time access to 180 hrs duration class videos, mock interviews and company referrals.
        60% coversion rate in FAANMGs. Best in industry.
      </h4>
      <b></b>
      <Link to="/apply">
        <button
          style={{
            marginTop: '20',
          }}
          type="submit"
        >
          Apply Now
        </button>
      </Link>
      <b></b>
      <h4>
        Get into the right mindset for Tech Interviews
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(`https://www.amazon.com/dp/B0C8NQPD4Z`, '_blank')}
        >
          <></> with O(1) Book
        </a>
      </h4>
    </div>
    <Features />
    <div className="Intro__content__timings">
      <p style={{ marginTop: '3rem' }}>
        <b>Course Duration SWE</b>: 17 Weeks
        <b>Course Duration Data Science</b>: 17 Weeks
        <b>Course Duration System Design</b>: 6 Weeks
      </p>
      <p style={{ marginTop: '3rem' }}>
        <b>For all tracks, Monday through Friday classes + Sunday mock interviews</b>
      </p>
      <p style={{ margin: '1rem' }}>
        <b>Software Engineering Interview Prep cohort</b>: August 19, 2024
      </p>
      <p style={{ margin: '1rem' }}>
        <b>Next Data Science cohort</b>: August 5, 2024 (26 Applicants Already, 2 Spots left)
      </p>
      <p style={{ margin: '1rem' }}>
        <b>Next System Design cohort</b>: September 2, 2024
      </p>
      {/* <p style={{ margin: '1rem' }}>
        <b>Data Science/Engineer Interview Prep cohort</b>:
      </p> */}
    </div>
    <Testimonials />
    {/* <TeamMembers /> */}
  </div>
);

export default Landing;
